<template>
  <div style="padding-top:50px;width:90%">
    <!--begin::Signup-->
    <div class="login-form login-signin w-100 pb-5">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Thank You</h3>
        <p class="font-weight-semi-bold">
          Once we have reviewed your information, we will contact you.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
};
</script>
